<template>
  <el-select
      :style="{}"
      size="small"
      v-model="storeId"
      :loading="isLoading"
      filterable
      clearable
      remote
      :remote-method="getList"
      placeholder="请输入搜索">
    <el-option v-for="item in list" :label="item.storeName" :value="item.storeName" :key="item.id"></el-option>
  </el-select>
</template>

<script>
import { getStoreListAPI } from './api.js'
export default {
  name: 'SelectStore',

  props: {
    value: {
      type: [String, Number],
      default: ''
    }
  },

  computed: {
    storeId: {
      get() {
        return this.value
      },

      set(val) {
        this.$emit('input', val)
      }
    }
  },

  data() {
    return {
      list: [],
      isLoading: false
    }
  },

  created() {
    this.getList('')
  },

  methods: {
    async getList(query) {
      let params = {
        page: 1,
        pageSize: 20,
        storeName: query
      }
      let res = await getStoreListAPI(params)
      this.list = res.list
    }
  }
}
</script>

<style></style>
